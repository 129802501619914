.root {
  background-image: url('https://source.unsplash.com/featured/?nhl');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.entries {
  height: 100%;
  padding: 1rem;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 1rem;
}

@media (min-width: 640px) {
  .entries {
    scroll-snap-type: x proximity;
  }
}