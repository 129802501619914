.root {
  font-size: 0.875rem;
  line-height: 1.375rem;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(0.5rem);
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.0625),
    0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.25);
  padding: 1rem;
  flex: 0 0 calc(100vw - 2rem);
  scroll-snap-align: start;
}

.root h2 {
  font-size: 1rem;
  line-height: 1.75rem;
}

.root header {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.root footer {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}

@media (min-width: 640px) {
  .root {
    max-width: 20rem;
    flex: 0 0 25vw;
  }
}