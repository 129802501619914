.root {
  color: white;
  background-color: black;
  width: 100vw;
  height: 100vh;
  padding-bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plot {
  width: 100%;
  max-width: 26rem;
  padding: 2rem;
}

/*
 * ELEMENTS
 */
.icon {
  font-size: 4rem;
}

.head {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.body {
  margin-top: 1rem;
}

/*
 * THEMES
 */
.error {
  background-color: black;
}

.error .icon::before {
  content: '😭';
}

.delay {
  background-image: linear-gradient(
    135deg,
    var(--mustard) 0%,
    var(--red) 50%,
    var(--teal) 100%
  );
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.delay .icon::before {
  content: '🐎';
}

/*
 * ANIMATIONS
 */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
