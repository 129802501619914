.root {
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: 0.02em;
  color: white;
  /* background-color: rgba(19, 59, 87, 0.5); */
  background-image: linear-gradient(90deg, var(--teal) 0%, var(--green) 100%);
  backdrop-filter: blur(0.5rem);
  padding: 1rem 2rem;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.root > :last-child {
  display: none;
}

.root b {
  font-weight: 600;
  color: var(--orange);
}

@media (min-width: 640px) {
  .root > :last-child {
    display: block;
  }
}
