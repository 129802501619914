@import url(https://cdn.jsdelivr.net/npm/@charsboo/secret-agent@1.0.0/dist/secret-agent.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
:root {
  --teal: #133b57;
  --orange: #e06543;
  --mustard: #e7a93f;
  --red: #d62867;
  --green: #119fa1;
}
.notice_root__JszOt {
  color: white;
  background-color: black;
  width: 100vw;
  height: 100vh;
  padding-bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice_plot__b6-Yy {
  width: 100%;
  max-width: 26rem;
  padding: 2rem;
}

/*
 * ELEMENTS
 */
.notice_icon__2uqzj {
  font-size: 4rem;
}

.notice_head__1be34 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.notice_body__2JKAi {
  margin-top: 1rem;
}

/*
 * THEMES
 */
.notice_error__1shOK {
  background-color: black;
}

.notice_error__1shOK .notice_icon__2uqzj::before {
  content: '😭';
}

.notice_delay__2yG-o {
  background-image: linear-gradient(
    135deg,
    var(--mustard) 0%,
    var(--red) 50%,
    var(--teal) 100%
  );
  background-size: 400% 400%;
  animation: notice_gradient__1XKwH 10s ease infinite;
}

.notice_delay__2yG-o .notice_icon__2uqzj::before {
  content: '🐎';
}

/*
 * ANIMATIONS
 */
@keyframes notice_gradient__1XKwH {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.entry-board_root___FFZn {
  font-size: 0.875rem;
  line-height: 1.375rem;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(0.5rem);
          backdrop-filter: blur(0.5rem);
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.0625),
    0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.25);
  padding: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100vw - 2rem);
  scroll-snap-align: start;
}

.entry-board_root___FFZn h2 {
  font-size: 1rem;
  line-height: 1.75rem;
}

.entry-board_root___FFZn header {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.entry-board_root___FFZn footer {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}

@media (min-width: 640px) {
  .entry-board_root___FFZn {
    max-width: 20rem;
    flex: 0 0 25vw;
  }
}
.entry-row_root__2VbFZ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_root__3Cnki {
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: 0.02em;
  color: white;
  /* background-color: rgba(19, 59, 87, 0.5); */
  background-image: linear-gradient(90deg, var(--teal) 0%, var(--green) 100%);
  -webkit-backdrop-filter: blur(0.5rem);
          backdrop-filter: blur(0.5rem);
  padding: 1rem 2rem;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_root__3Cnki > :last-child {
  display: none;
}

.header_root__3Cnki b {
  font-weight: 600;
  color: var(--orange);
}

@media (min-width: 640px) {
  .header_root__3Cnki > :last-child {
    display: block;
  }
}

.main_root__2LEGR {
  background-image: url('https://source.unsplash.com/featured/?nhl');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main_entries__2jJBH {
  height: 100%;
  padding: 1rem;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  overflow-x: auto;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scroll-padding-left: 1rem;
}

@media (min-width: 640px) {
  .main_entries__2jJBH {
    -ms-scroll-snap-type: x proximity;
        scroll-snap-type: x proximity;
  }
}
